<template>
  <div>
    <app-breadcrumb section="Sistema" :breadcumbs="['Permisos']" />

    <div class="row">
      <div class="col">
        <b-overlay :show="loading_data" rounded="sm">
          <b-card>
            <b-card-body>
              <b-tabs>
                <b-tab
                  v-for="(role, index) of roles"
                  :key="index"
                  :title="role.name"
                >
                  <b-card-text>
                    <div class="row">
                      <div class="col">
                        <b-tabs>
                          <b-tab
                            v-for="(item, index) of role.modules"
                            :key="index"
                            :title="item.name"
                          >
                            <b-card-text>
                              <div
                                class="d-flex align-items-center justify-content-end"
                              >
                                <b-form-checkbox
                                  switch
                                  size="lg"
                                  v-model="item.check"
                                  @change="
                                    changeStatusModulePermission({
                                      ...item,
                                      role_id: role.id
                                    })
                                  "
                                  color="primary"
                                >
                                  <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                  </span>
                                  <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                  </span>
                                </b-form-checkbox>
                              </div>
                              <div class="card">
                                <div class="card-body">
                                  <div
                                    v-if="item.submodules.length"
                                    class="table-responsive mb-0"
                                  >
                                    <table
                                      class="table table-nowrap table-centered"
                                    >
                                      <tbody>
                                        <tr
                                          v-for="(submodule,
                                          index) of item.submodules"
                                          :key="index"
                                        >
                                          <td style="width: 60px;">
                                            <div
                                              class="custom-control custom-checkbox"
                                            >
                                              <input
                                                v-model="submodule.status"
                                                @change="
                                                  changeStatusSubModulePermission(
                                                    submodule,
                                                    role.id
                                                  )
                                                "
                                                :id="
                                                  `customCheck1${submodule.id}-${role.id}`
                                                "
                                                type="checkbox"
                                                class="custom-control-input"
                                              />
                                              <label
                                                class="custom-control-label"
                                                :for="
                                                  `customCheck1${submodule.id}-${role.id}`
                                                "
                                              ></label>
                                            </div>
                                          </td>
                                          <td>
                                            <h5
                                              class="text-truncate font-size-14 m-0"
                                            >
                                              <a
                                                href="javascript: void(0);"
                                                class="text-dark"
                                              >
                                                {{ submodule.name }}
                                              </a>
                                            </h5>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div v-else>
                                    <h3 class="text-center">
                                      Este modulo no tiene submodulos
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </b-card-text>
                          </b-tab>
                        </b-tabs>
                      </div>
                    </div>
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card-body>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/user.service";
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import {
  BTabs,
  BTab,
  BOverlay,
  BCard,
  BCardBody,
  BCardText,
  BFormCheckbox
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormCheckbox,
    AppBreadcrumb,
    BTabs,
    BTab,
    BOverlay,
    BCard,
    BCardBody,
    BCardText
  },
  data() {
    return {
      roles: [],
      loading_data: false
    };
  },
  methods: {
    async getRolesAndPermissions() {
      this.loading_data = true;
      const { data: res } = await UserService.getRolesAndPermissions();
      this.roles = res.data;
      this.loading_data = false;
    },
    async changeStatusModulePermission(data) {
      const { data: res } = await UserService.changeStatusModulePermission({
        module_id: data.id,
        role_id: data.role_id,
        status: data.check
      });
      if (res.success) {
        this.toast(res.message);
      } else {
        Swal.fire(res.message, "", "error");
      }
    },
    async changeStatusSubModulePermission(data, role_id) {
      const { data: res } = await UserService.changeStatusSubModulePermission({
        submodule_id: data.id,
        role_id: role_id,
        status: data.status
      });
      if (res.success) {
        this.toast(res.message);
      } else {
        Swal.fire(res.message, "", "error");
      }
    },
    toast(title, variant = "success", icon = "CheckCircleIcon") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant
        }
      });
    }
  },
  created() {
    this.getRolesAndPermissions();
  }
};
</script>
